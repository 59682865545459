<template>
  <v-content>
    <v-container v-if="_.size(order) > 0" grid-list-lg fluid>
      <div class="d-print-none">
        <v-btn color="error" block @click="print"> <v-icon left>dock</v-icon> Imprimir POS</v-btn>
      </div>
      <h1 class="display-1 mb-0">
        {{ order.id }}
      </h1>
      <p class="subheading mb-0">{{ order.shipping.date | moment('dddd DD MMMM YYYY') }}</p>
      <p class="body-2 mb-2">{{ order.shipping.schedule }}</p>
      <hr class="mb-2" />
      <p class="body-2 mb-0">
        {{ order.shipping.name }}
      </p>
      <p class="mb-0">{{ order.shipping.address }} {{ order.shipping.address_number }}</p>
      <p v-if="order.shipping.more" class="mb-0">
        {{ order.shipping.more }}
      </p>
      <p v-if="order.shipping.commune" class="mb-0">
        {{ order.shipping.commune }}
      </p>
      <p v-if="order.shipping.phone" class="mb-2">
        {{ order.shipping.phone }}
      </p>
      <v-alert v-if="order.shipping.photo_hidden" :value="true" type="error">
        Foto de entrega NO debe ser con la persona recibiendo
      </v-alert>
      <v-alert v-if="maxQuantity" :value="true" type="warning"> Este pedido lleva mas de 1 PRODUCTO </v-alert>
      <v-alert v-if="order.priority.name === 'alta'" :value="true" type="error">
        Debe ser entregado antes de las {{ order.priority.hour }} hrs
      </v-alert>
      <hr class="mb-2" />
      <div v-for="(pro, e) in order.products" :key="e">
        <p class="mb-0">{{ pro.quantity }} x {{ pro.name }} ({{ pro.id }})</p>
        <p v-if="pro.note" class="mb-0 caption">
          {{ pro.note }}
        </p>
        <p v-for="(atributo, index) in pro.atributos" :key="index" class="mb-0 caption">{{ atributo.name }}</p>
        <p v-if="pro.note_admin" class="mb-0 overline">
          {{ pro.note_admin }}
        </p>
        <hr v-if="e + 1 !== order.products.length" class="my-3 light" />
      </div>
      <hr class="my-2" />
      <p v-if="order.shipping.type === 'ANONIMO'" class="headline font-weight-bold mb-2">Envío anonimo</p>
      <div v-if="order.shipping.type !== 'ANONIMO'">
        <p class="mb-0">
          {{ order.user.name }}
        </p>
        <p class="mb-0">{{ order.user.email }}</p>
        <p class="mb-2">+{{ order.user.phone }}</p>
      </div>
      <hr class="my-3" />
      <p class="caption" v-html="order.message"></p>
      <hr class="my-3 dashed" />
      <h4 class="headline text-xs-center">
        {{ order.shipping.name }}
      </h4>
      <h3 class="headline text-xs-center">
        {{
          `${order.shipping.address} ${order.shipping.address_number} ${order.shipping.more} ${order.shipping.commune}`
        }}
      </h3>
    </v-container>
  </v-content>
</template>

<script>
import { ORDER } from '../../config'

export default {
  name: 'OrderPrint',
  props: ['id'],
  data() {
    return {
      printers: null,
      allDoc: [],
      center: { lat: -33.4262, lng: -70.6429 },
      zoom: 10,
      options: {
        styles: [],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      },
      latLngOrder: {},
      order: {},
      schedule: {},
      windowHeight: window.innerHeight - 420
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    height() {
      return `${window.innerHeight - 80}px`
    },
    maxQuantity() {
      let quantity = false
      this.order.products.forEach(element => {
        if (element.quantity > 1) {
          quantity = true
        }
      })
      return quantity
    }
  },
  watch: {
    id() {
      this.getOrder()
    }
  },
  mounted() {
    this.getOrder()
  },
  methods: {
    printPOS() {
      this.formatPrint(this.order)
    },
    formatPrint(o) {
      const info = o.shipping.more !== '' ? `${o.shipping.more}` : ''
      let products = ''
      let destacado = ''
      let noPhoto = ''
      let express = ''
      if (o.priority.name === 'alta') {
        express = `Debe ser entregado antes de las ${o.priority.hour} hrs`
      }
      const date = `${this.$moment(o.shipping.date).format('dddd')} ${this.$moment(o.shipping.date).format(
        'DD'
      )} de ${this.$moment(o.shipping.date).format('MMMM')}, ${this.$moment(o.shipping.date).format('YYYY')}`
      let numProduct = 0
      o.products.forEach(e => {
        numProduct++
        let atributos = '\x0A'
        e.atributos.forEach(a => {
          atributos += `[${a.name}]\x0A`
        })
        products += `${e.quantity} x ${e.name} (P${e.id}) ${atributos}`
        if (e.comment) {
          products += `${e.comment}\x0A`
        }
        if (numProduct !== o.products.length) {
          products += '---\x0A'
        }
      })
      o.importants.forEach(e => {
        destacado += `${e.message.trim()}\x0A`
      })
      destacado += destacado !== '' ? '\x0A' + '------------------------------------------------' + '\x0A' + '\x0A' : ''
      noPhoto = o.shipping.photo_hidden ? 'Foto de entrega NO debe ser con la persona recibiendo' + '\x0A' : ''
      let data = [
        '\x1B' + '\x40', // init
        '\x1B' + '\x61' + '\x30', // right align
        '\x1B' + '\x21' + '\x30', // em mode on
        `Pedido ${this.$options.filters.toPriceOut(o.id)}`,
        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
        '\x0A',
        '\x1B' + '\x21' + '\x30', // em mode on
        `${o.shipping.name}` + '\x0A',
        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
        '\x1b\x21\x20', // double width
        '\x1B' + '\x45' + '\x0D', // bold on
        `${o.shipping.address} ${o.shipping.address_number}` + '\x0A',
        `${info}` + '\x0A',
        `${o.shipping.commune}` + '\x0A',
        `${o.shipping.phone ? o.shipping.phone + '\x0A' : ''}`,
        '\x1B' + '\x45' + '\x0A', // bold off
        '\x1b\x21\x00', // double width off
        '------------------------------------------------' + '\x0A' + '\x0A',
        noPhoto ? '\x1B' + '\x45' + '\x0D' : '',
        `${noPhoto}`,
        noPhoto ? '\x1B' + '\x45' + '\x0A' : '',
        express ? '\x1B' + '\x45' + '\x0D' : '',
        `${express}`,
        express ? '\x1B' + '\x45' + '\x0A' : '',
        '\x1B' + '\x4D' + '\x30', // normal text
        `${date}` + '\x0A',
        '\x1B' + '\x45' + '\x0D', // bold on
        `${o.shipping.schedule}`,
        '\x1B' + '\x45' + '\x0A', // bold off
        '\x0A' + '\x0A',
        '\x1B' + '\x4D' + '\x30', // normal text
        '------------------------------------------------' + '\x0A' + '\x0A',
        `${products}`,
        '\x0A' + '------------------------------------------------' + '\x0A' + '\x0A',
        o.shipping.type === 'NORMAL' ? `${o.user.name}` + '\x0A' : '',
        o.shipping.type === 'NORMAL' ? `+${o.user.phone}` + '\x0A' + '\x0A' : 'ENVIO ANONIMO' + '\x0A' + '\x0A',
        '------------------------------------------------' + '\x0A',
        `${o.message.replace(/<[^>]*>?/gm, '')}` + '\x0A' + '\x0A',
        '------------------------------------------------' + '\x0A' + '\x0A',
        `${destacado}`,
        '\x0A' + '\x0A' + '\x0A', // saltos de linea antes de cut
        '\x1D' + '\x56' + '\x00', // full cut (new syntax)
        '\x10' + '\x14' + '\x01' + '\x00' + '\x05'
      ]
      data = data.map(e => {
        return e.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      })
      this.addPrinter(data)
    },
    addPrinter(d) {
      const printer = this.settings.printer
      d.forEach(e => {
        this.allDoc.push(e)
      })
      const config = qz.configs.create(printer, { encoding: 'ISO-8859-1' })
      qz.print(config, this.allDoc)
      this.allDoc = []
    },
    print() {
      window.print()
    },
    updateform() {
      this.schedule.date = this.order.shipping.date
      this.schedule.name = this.order.shipping.schedule
      this.latLngOrder = {
        lat: this.order.shipping.latitude,
        lng: this.order.shipping.longitude
      }
      this.center = this.latLngOrder
    },
    async getOrder() {
      const res = await this.$http.get(`${ORDER}/${this.id}`)
      if (res.data) {
        this.order = res.data
        this.$store.dispatch('updateTitle', `Pedido ${this.order.id}`)
        this.updateform()
      }
    }
  }
}
</script>

<style>
@page {
  size: 80mm 297mm; /* Ancho x Alto */
  margin: 0; /* Márgenes opcionales */
}

hr {
  border-color: rgba(0, 0, 0, 1);
  border-top: 0;
}
hr.light {
  border-color: rgba(0, 0, 0, 0.5);
  border-top: 0;
}
hr.dashed {
  border-top: 1px dashed rgba(0, 0, 0, 1);
  border-bottom: 0;
}

@media print {
  body {
    width: 80mm;
    height: 297mm;
    margin: 0;
    padding: 0;
    font-size: 12px; /* Ajusta el tamaño de la fuente según sea necesario */
  }

  .container,
  .v-content {
    padding: 0 !important;
  }

  /* Estilos adicionales para elementos específicos */
  .ticket {
    width: 100%;
    padding: 2mm;
    box-sizing: border-box;
  }

  .ticket-header,
  .ticket-footer {
    text-align: center;
    font-weight: bold;
  }

  .ticket-content {
    margin-top: 10mm;
  }
}
</style>
