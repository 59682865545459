import { render, staticRenderFns } from "./printPos.vue?vue&type=template&id=6deb6b36&"
import script from "./printPos.vue?vue&type=script&lang=js&"
export * from "./printPos.vue?vue&type=script&lang=js&"
import style0 from "./printPos.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
installComponents(component, {VAlert,VBtn,VContainer,VContent,VIcon})
